<template>
  <!-- <div class="content-box"> -->
  <iframe class="iframe-box" :src="src" scrolling="auto" frameborder="0" width="100%" height="100%"></iframe>
  <!-- </div> -->
</template>

<script>
export default {
  data() {
    return {
      src: this.$route.query.url,
    };
  },
  mounted() {
    document.title = this.$route.query.title;
  },
  methods: {},
  beforeCreate() {
    this.$nextTick(() => {
      document.body.setAttribute("style", "padding: 3.467vw;box-sizing: border-box;");
    });
  },
  //实例销毁之前钩子--移除body 标签的属性style
  beforeDestroy() {
    document.body.removeAttribute("style");
  },
};
</script>
<style lang="less" scoped>
.iframe-box {
  width: 698px;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: #fff;
}
</style>
